$white: #ffffff;
$gray: #c4c4c4;
$silver: #c7c7c7;
$white_smoke: #f8f9fa;
$black: #333333;
$menuHover: #e6e6e6;
$silver: #c7c7c7;
$segmenter_line: #6677ff;
$menuHover: #e6e6e6;
$primary: #3f51b5;
$doveGray: #707070;
$default_bar_bg: #ddd;
$black60: #858585;
$blackBackground: rgba(
  $color: $black,
  $alpha: 0.3,
);
